.profileHeaderCover {
	--aspect-ratio: 8 / 3;
	--background-color: var(--color-neutral-white);

	aspect-ratio: var(--aspect-ratio);
	background-color: var(--background-color);
	height: 10.125rem;
	position: relative;
	width: 100%;

	& img {
		object-fit: cover;
		object-position: center;
		width: 100%;
	}
}

.profileHeaderInfo {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-m);
	margin: -5.625rem auto 0;
	position: relative;
	text-align: center;
	width: var(--max-width-content);
}

.profileHeaderAvatar {
	margin: 0 auto;
}

.profileHeaderContributor {
	--color: var(--color, currentColor);
	--font-family: var(--typography-eyebrow-family);
	--font-size: var(--typography-eyebrow-size);
	--font-weight: var(--typography-eyebrow-weight);
	--letter-spacing: var(--typography-eyebrow-letter-spacing);
	--line-height: var(--typography-eyebrow-line-height);

	@media (--bp-large) {
		--font-family: var(--typography-eyebrow-desktop-family);
		--font-size: var(--typography-eyebrow-desktop-size);
		--font-weight: var(--typography-eyebrow-desktop-weight);
		--letter-spacing: var(--typography-eyebrow-desktop-letter-spacing);
		--line-height: var(--typography-eyebrow-desktop-line-height);
	}

	color: var(--color, currentColor);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.profileHeaderName {
	--color: var(--color, currentColor);
	--font-family: var(--typography-h-xl-family);
	--font-size: var(--typography-h-xl-size);
	--font-weight: var(--typography-h-xl-weight);
	--letter-spacing: var(--typography-h-xl-letter-spacing);
	--line-height: var(--typography-h-xl-line-height);

	@media (--bp-large) {
		--font-family: var(--typography-h-xl-desktop-family);
		--font-size: var(--typography-h-xl-desktop-size);
		--font-weight: var(--typography-h-xl-desktop-weight);
		--letter-spacing: var(--typography-h-xl-desktop-letter-spacing);
		--line-height: var(--typography-h-xl-desktop-line-height);
	}

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.profileHeaderDescription {
	--color: var(--color, currentColor);
	--font-family: var(--typography-body-family);
	--font-size: var(--typography-body-size);
	--font-weight: var(--typography-body-weight);
	--letter-spacing: var(--typography-body-letter-spacing);
	--line-height: var(--typography-body-line-height);

	@media (--bp-large) {
		--font-family: var(--typography-body-desktop-family);
		--font-size: var(--typography-body-desktop-size);
		--font-weight: var(--typography-body-desktop-weight);
		--letter-spacing: var(--typography-body-desktop-letter-spacing);
		--line-height: var(--typography-body-desktop-line-height);
	}

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	text-align: left;
}
