.articleTile {
	--transition-speed-normal: var(--motion-speed-short);

	display: flex;
	flex-direction: column;
	margin: 0;
	position: relative;
	row-gap: var(--spacing-2xs);

	&:hover,
	&:focus-within {

		& .articleTileImage {
			transform: scale(1.05);
		}

		& .articleTileCta {
			text-decoration: none;
		}
	}
}

.articleTileImageWrapper {
	--border-radius: var(--radius-media);

	border-radius: var(--border-radius);
	margin: 0;
	overflow: hidden;
	position: relative;
}

.articleTileImageWrapperStroke {
	--border: var(--color-neutral-dark-10) 1px solid;

	border: var(--border);
}

.articleTileImage {
	display: block;
	height: 100%;
	object-fit: cover;
	transition: transform var(--transition-speed-normal) ease-out;
	width: 100%;
}

.articleTileContent {
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-3xs);
}

.articleTileMetaCat {
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-2xs);
}

.articleTileTags {
	--color: var(--card-color-category);
	--font-family: var(--typography-eyebrow-family);
	--font-weight: var(--typography-eyebrow-weight);
	--font-size: var(--typography-eyebrow-size);
	--line-height: var(--typography-eyebrow-line-height);
	--letter-spacing: var(--typography-eyebrow-letter-spacing);

	align-items: center;
	color: var(--color);
	column-gap: var(--spacing-3xs);
	display: flex;
	flex-wrap: wrap;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	list-style: none;
	margin-bottom: 0;
	padding: 0;

	@media (--bp-large) {
		--font-family: var(--typography-eyebrow-desktop-family);
		--font-weight: var(--typography-eyebrow-desktop-weight);
		--font-size: var(--typography-eyebrow-desktop-size);
		--line-height: var(--typography-eyebrow-desktop-line-height);
		--letter-spacing: var(--typography-eyebrow-desktop-letter-spacing);
	}

	& li {
		align-items: center;
		display: flex;
		margin-top: 0 !important; /* Override default margin for lists in content class */

		&:not(:first-child)::before {
			background-color: var(--color);
			border-radius: 50%;
			content: "";
			display: inline-block;
			height: 4px;
			margin-right: var(--spacing-3xs);
			width: 4px;
		}
	}
}

.articleTileCategory {
	--font-family: var(--card-typography-category-family);
	--font-weight: var(--card-typography-category-weight);
	--font-size: var(--card-typography-category-size);
	--line-height: var(--card-typography-category-line-height);
	--letter-spacing: var(--card-typography-category-letter-spacing);

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	margin-bottom: 0;
	text-transform: uppercase;
}

a.articleTileCategory {
	position: relative;
	z-index: 1;
}

:global(.carousel) {

	& .articleTileTitle {
		padding: 4px;
	}
}

.articleTileTitle,
.articleTileTitle a {
	--font-family: var(--card-typography-title-family);

	font-family: var(--font-family);
}

.articleTileTitle a {
	--color: currentColor;
	--font-family: var(--card-typography-title-stacked-family);
	--font-weight: var(--card-typography-title-stacked-weight);
	--font-size: var(--card-typography-title-stacked-size);
	--line-height: var(--card-typography-title-line-height);
	--letter-spacing: var(--card-typography-title-letter-spacing);

	display: block;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.articleTileLink {
	color: var(--color, currentColor);
	display: block;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	text-decoration: none;
	transition: opacity var(--transition-speed-normal);

	&::after {
		content: "";
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&:hover,
	&:focus {
		opacity: 0.8;
		text-decoration: none;
	}
}

.articleTile + .articleTileAuthor {
	--font-size: var(--typography-body-s-size);
	--font-weight: 700;

	font-size: var(--font-size);
	font-weight: var(--font-weight);
}

.articleTileMeta {
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-4xs);
}

.articleTileAuthor {
	--color: var(--color-neutral-dark-70);
	--font-family: var(--card-typography-contributor-name-family);
	--font-size: var(--card-typography-contributor-name-size);
	--font-weight: var(--card-typography-contributor-name-weight);
	--line-height: var(--card-typography-contributor-name-line-height);
	--letter-spacing: var(--card-typography-contributor-name-letter-spacing);

	@media (--bp-medium) {
		--font-family: var(--card-typography-contributor-name-desktop-family);
		--font-size: var(--card-typography-contributor-name-desktop-size);
		--font-weight: var(--card-typography-contributor-name-desktop-weight);
		--line-height: var(--card-typography-contributor-name-desktop-line-height);
		--letter-spacing: var(--card-typography-contributor-name-desktop-letter-spacing);
	}

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	text-transform: uppercase;
}

a.articleTileAuthor {
	position: relative;
	z-index: 1;
}

.articleTileDate {
	--color: var(--card-color-date);
	--font-family: var(--card-typography-date-family);
	--font-weight: var(--card-typography-date-weight);
	--font-size: var(--card-typography-date-size);
	--line-height: var(--card-typography-date-line-height);
	--letter-spacing: var(--card-typography-date-letter-spacing);

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.articleTile + .articleTileDate {
	--font-size: var(--typography-body-s-size);

	display: block;
	font-size: var(--font-size);
}

.articleTileDescription {
	--font-family: var(--card-typography-body-family);
	--font-weight: var(--card-typography-body-weight);
	--font-size: var(--card-typography-body-size);
	--line-height: var(--card-typography-body-line-height);
	--letter-spacing: var(--card-typography-body-letter-spacing);

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.articleTileCta {
	--font-family: var(--card-typography-body-family);
	--font-weight: var(--card-typography-body-weight);
	--font-size: var(--card-typography-body-size);
	--line-height: var(--card-typography-body-line-height);
	--letter-spacing: var(--card-typography-body-letter-spacing);

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	text-decoration: underline;
	z-index: 1;

	&:hover {
		text-decoration: none;
	}
}

/* Variants */

.articleTileCategoryVariation {
	text-align: center;
}

.articleTileCompact {
	align-items: center;
	column-gap: var(--spacing-m);
	flex-direction: row;

	& .articleTileImageWrapper {
		aspect-ratio: 1 / 1;
		flex: 0 0 90px;
	}

	& .articleTileTitle a {
		--font-family: var(--card-typography-title-family);
		--font-weight: 700;
		--font-size: var(--card-typography-title-size);
		--line-height: calc(var(--card-typography-title-line-height) + 0.4); /* Temporary fix for line-clamp */
		--letter-spacing: var(--card-typography-title-letter-spacing);

		-webkit-box-orient: vertical;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	& .articleTileDescription {
		-webkit-box-orient: vertical;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.articleTileGridwall {

	& .articleTileImageWrapper {
		aspect-ratio: 4 / 3;
	}
}

.articleTileHorizontal {
	align-items: center;
	column-gap: 0.625rem;
	display: flex;
	flex-direction: row;
	grid-template-columns: 1fr 1fr;

	& .articleTileImageWrapper {
		aspect-ratio: 1 / 1;
		flex: 0 0 146px;

		@media (--bp-large) {
			flex: 0 0 180px;
		}
	}
}

.articleTileWide {
	align-items: center;
	column-gap: var(--spacing-m);
	flex-direction: row;

	& .articleTileImageWrapper {
		aspect-ratio: 16 / 9;
		flex: 0 0 128px;

		@media (--bp-large) {
			flex: 0 0 202px;
		}
	}

	& .articleTileCategory {
		--font-family: var(--typography-eyebrow-family);
		--font-weight: var(--typography-eyebrow-weight);
		--font-size: var(--typography-eyebrow-size);
		--letter-spacing: var(--typography-eyebrow-letter-spacing);
		--line-height: var(--typography-eyebrow-line-height);

		@media (--bp-large) {
			--font-family: var(--typography-eyebrow-desktop-family);
			--font-weight: var(--typography-eyebrow-desktop-weight);
			--font-size: var(--typography-eyebrow-desktop-size);
			--line-height: var(--typography-eyebrow-desktop-line-height);
			--letter-spacing: var(--typography-eyebrow-desktop-letter-spacing);
		}
	}

	& .articleTileTitle a {
		--font-family: var(--typography-h-s-family);
		--font-weight: var(--typography-h-s-weight);
		--font-size: var(--typography-h-s-size);
		--line-height: calc(var(--typography-h-s-line-height) + 0.4); /* Temporary fix for line-clamp */
		--letter-spacing: var(--typography-h-s-letter-spacing);

		-webkit-box-orient: vertical;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		overflow: hidden;
		text-overflow: ellipsis;

		@media (--bp-large) {
			--font-family: var(--typography-h-s-desktop-family);
			--font-weight: var(--typography-h-s-desktop-weight);
			--font-size: var(--typography-h-s-desktop-size);
			--line-height: calc(var(--typography-h-s-desktop-line-height) + 0.4); /* Temporary fix for line-clamp */
			--letter-spacing: var(--typography-h-s-desktop-letter-spacing);
		}
	}

	& .articleTileDescription {
		-webkit-box-orient: vertical;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.articleTile:not(.articleTileCompact):not(.articleTileHorizontal):not(.articleTileWide):not(.articleTileGridwall) .articleTileImageWrapper {
	aspect-ratio: 4 / 5;
}

.articleTileImageWrapper169 {
	aspect-ratio: 16 / 9 !important;
}

.articleTileImageWrapper43 {
	aspect-ratio: 4 / 3 !important;
}

.articleTileImageWrapper11 {
	aspect-ratio: 1 / 1 !important;
}

.articleTileImageWrapper45 {
	aspect-ratio: 4 / 5 !important;
}

@container style(--is-dark-mode: 1) {

	.articleTile,
	.articleTileCategory,
	.articleTileTitle {
		--color: var(--color-neutral-white);
	}

	.articleTileAuthor,
	.articleTileDate {
		--color: var(--color-neutral-light-70);
	}
}
