.subNavigation {
	--background-color: var(--navigation-color-background);
	--border-style: var(--navigation-border-width) var(--navigation-border-style) var(--navigation-color-border);
	--color: var(--navigation-color-text);
	--height-text: calc(var(--navigation-typography-link-l2-size) * var(--navigation-typography-link-l2-line-height));
	--height-spacing: calc(var(--spacing-3xs-desktop) * 2 + var(--navigation-border-width));
	--height: calc(var(--height-text) + var(--height-spacing));

	background-color: var(--background-color);
	border-bottom: var(--border-style);
	color: var(--color);
	height: var(--height);
	padding: var(--spacing-3xs-desktop) var(--spacing-2xs);
	position: relative;
	width: 100%;

	@media (--allows-motion) {
		transition: margin-top var(--transition-speed-slow) ease-in-out var(--transition-speed-slow);
	}

	@media (--bp-large) {
		padding: var(--spacing-3xs-desktop);
	}

	&::before,
	&::after {
		content: "";
		height: 100%;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		transition: opacity var(--transition-speed-fast);
		width: 32px;
		z-index: 1;
	}

	&::before {
		background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, var(--background-color) 100%);
		left: var(--spacing-2xs);

		@media (--bp-large) {
			left: var(--spacing-3xs-desktop);
		}
	}

	&::after {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--background-color) 100%);
		right: var(--spacing-2xs);

		@media (--bp-large) {
			right: var(--spacing-3xs-desktop);
		}
	}

	&[aria-hidden="true"] {
		margin-top: calc(var(--height) * -1);
	}
}

.subNavigationScrollWrapperScrollable::after {
	opacity: 1;
}

.subNavigationScrollWrapperScrolling::before,
.subNavigationScrollWrapperScrolling::after,
.subNavigationScrollWrapperScrolled::before {
	opacity: 1;
}

.subNavigationScrollWrapperScrolled::after {
	opacity: 0;
}

.subNavigationScrollWrapper {
	align-items: center;
	display: flex;
	flex-wrap: nowrap;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none; /* IE and Edge */
	overflow-x: auto;
	position: relative;
	scrollbar-width: none; /* Firefox */

	@media (--bp-large) {
		justify-content: center;
		padding: 0;
	}

	&::-webkit-scrollbar {
		display: none;
	}
}

.subNavigationTitleWrapper {
	align-items: center;
	border-right: var(--border-style);
	display: flex;
	flex: 0 0 auto;
	flex-wrap: nowrap;
	margin-right: var(--spacing-xs);
	padding-right: var(--spacing-xs);

	@media (--bp-large) {
		margin-right: var(--spacing-xs-desktop);
		padding-right: var(--spacing-xs-desktop);
	}
}

.subNavigationIcon {
	display: block;
	height: 20px;
	margin-right: var(--spacing-3xs);
	object-fit: contain;
	width: 20px;
}

.subNavigationTitle {
	--font-family: var(--navigation-typography-link-l2-family);
	--font-size: var(--navigation-typography-link-l2-size);
	--font-weight: var(--navigation-typography-link-l1-weight); /* Using L1 weight for title for differentiation */
	--letter-spacing: var(--navigation-typography-link-l2-letter-spacing);
	--line-height: var(--navigation-typography-link-l2-line-height);
}

.subNavigationListWrapper {
	flex: 0 1 auto;
	padding: 0;
}

.subNavigationList {
	display: flex;
	flex-wrap: nowrap;
	gap: var(--spacing-xs);
	list-style: none;
	padding: 0;

	@media (--bp-large) {
		gap: var(--spacing-xs-desktop);
	}

	&::-webkit-scrollbar {
		display: none;
	}
}

.subNavigationItem {
	flex: 1 0 auto;
}

.subNavigationLink {
	--color: var(--navigation-color-link);
	--font-family: var(--navigation-typography-link-l2-family);
	--font-size: var(--navigation-typography-link-l2-size);
	--font-weight: var(--navigation-typography-link-l2-weight);
	--letter-spacing: var(--navigation-typography-link-l2-letter-spacing);
	--line-height: var(--navigation-typography-link-l2-line-height);

	display: block;

	&:hover {
		--color: var(--navigation-color-link);
	}
}

.subNavigationLinkActive {
	--font-weight: var(--navigation-typography-link-l1-weight);
}
