.stickyElement {
	position: sticky;
	top: 0;
	transition: box-shadow 0.5s;
	z-index: 100;
}

.stickyElementStuck {
	box-shadow: var(--nav-shadow);
}
