.footerAdSlot {
	background-color: rgb(243, 243, 243);
	bottom: 0;
	left: 0;
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
	position: fixed;
	right: 0;
	transition: opacity var(--transition-speed-normal);
	z-index: 51;

	&.isShown {
		opacity: 1;
		pointer-events: auto;
	}

	@media (--allows-motion) {
		transform: translate(0, 100%);
		transition: opacity var(--transition-speed-normal), transform var(--transition-speed-normal);

		&.isShown {
			transform: translate(0, 0);
		}
	}
}

.closeButton {
	background-color: var(--color-neutral-dark-5);
	border: none;
	color: var(--color-neutral-black);
	cursor: pointer;
	height: 1.5rem;
	opacity: 0.75;
	padding: 0.25rem;
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity var(--transition-speed-fast);
	width: 1.5rem;

	@media (--bp-medium) {
		height: 2rem;
		width: 2rem;
	}

	& svg {
		height: 100%;
		width: 100%;
	}

	&:hover {
		opacity: 1;
	}
}
