.adSlot-in_content {
	background-color: var(--color-neutral-dark-5);
	border-bottom: 1px solid var(--separator-color);
	border-top: 1px solid var(--separator-color);
	margin-block: var(--spacing-xl);
	margin-inline: var(--margin-full-width);
	overflow: hidden;
	padding-bottom: var(--spacing-2xs);
	padding-top: var(--spacing-3xs);

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	& > div::before {
		color: var(--color, currentColor);
		content: "Advertising";
		display: block;
		font-size: var(--typography-body-s-desktop-size);
		font-weight: var(--typography-eyebrow-weight);
		margin: 0 auto var(--spacing-4xs);
		text-align: center;
	}
}

:global(.wp-block-group) + .adSlot-in_content {
	margin-block: 0;
}

.adSlot-in_content + :global(.wp-block-group) {
	margin-top: 0;
}

.adSlot-interstitial {
	position: absolute;
}
