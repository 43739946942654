.privacySettingsButton {
	align-items: center;
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	font-size: var(--footer-typography-link-desktop-size);
	padding: 0;

	text-decoration: underline;
	text-decoration-color: var(--footer-color-link);
	text-decoration-thickness: 1px;
	text-underline-offset: 0.1rem;
	transition: color var(--transition-speed-fast);

	&:hover,
	&:focus {
		color: var(--footer-color-link-hover);
	}

	& > svg {
		margin-left: 4px;
	}
}
