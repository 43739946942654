.networksList {
	column-gap: var(--spacing-2xs);
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	row-gap: var(--spacing-2xs);

	@media (--bp-large) {
		column-gap: var(--spacing-2xs-desktop);
		row-gap: var(--spacing-2xs-desktop);
	}
}
