.newsletterDropdown {
	--background-color: var(--navigation-color-background);

	animation: fadeOutDrodown var(--transition-speed-normal) ease-out forwards;
	background-color: var(--background-color);
	height: calc(100vh - 64px);
	opacity: 0;
	position: absolute;
	top: var(--header-height);
	transform: scaleY(1) translateY(0);
	visibility: hidden;
	width: 100%;
	z-index: -1;

	@media (--bp-large) {
		height: auto;
	}
}

.newsletterDropdownActive {
	animation: bounceInDownDropdown var(--transition-speed-normal) ease-out forwards;
	animation-delay: var(--transition-speed-normal);
	transform-origin: top;
	visibility: visible;
	z-index: 150;
}

.newsletterDropdownInfo {

	@media (--bp-large) {
		flex: 0 1 437px;
	}

	& h2 {
		--font-family: var(--typography-body-family);
		--font-size: 1.375rem;
		--font-weight: var(--typography-btn-desktop-weight);
		--line-height: 1.24;

		@media (--bp-large) {
			--font-size: 2rem;
		}

		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
		line-height: var(--line-height);
		margin-bottom: var(--spacing-xs);
	}

	& p {
		--font-family: var(--typography-body-family);
		--font-size: var(--typography-body-s-size);
		--font-weight: 400;
		--line-height: 1.54;

		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
		line-height: var(--line-height);
	}
}

.newsletterDropdownFormContainer {

	@media (--bp-large) {
		flex: 0 1 551px;
	}
}

.newsletterDropdownForm {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xs);
	justify-content: space-between;

	@media (--bp-large) {
		flex-direction: row;
	}
}

.newsletterDropdownFormInput {
	flex: 1;
}

.newsletterDropdownFormButton {
	justify-content: center;
	padding: 1rem 1.25rem;
}

.newsletterDropdownCopyRight {
	color: var(--color-neutral-dark-60);
	font-size: 0.75rem;
	margin-top: var(--spacing-s);
}

@keyframes fadeOutDrodown {

	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		z-index: -1;
	}
}

@keyframes bounceInDownDropdown {

	0% {
		opacity: 0;
		transform: scaleY(0.8) translateY(-20px);
	}

	60% {
		opacity: 1;
		transform: scaleY(1.025) translateY(0);
	}

	80% {
		transform: scaleY(0.975);
	}

	100% {
		opacity: 1;
		transform: scaleY(1);;
	}
}
