.imageCaption {
	background: var(--color-overlay-horizontal);
	bottom: 0;
	color: var(--color-neutral-white);
	left: 0;
	max-height: 100%;
	opacity: 0;
	overflow-y: auto;
	overscroll-behavior-block: contain;
	padding: var(--spacing-2xs) var(--spacing-3xl) var(--spacing-xs);
	position: absolute;
	text-align: right;
	transition: visibility 0.3s, opacity var(--transition-speed-normal) ease-in-out;
	visibility: hidden;
	width: 100%;
	z-index: 10;

	&.imageCaptionVisible {
		opacity: 1;
		visibility: visible;
	}
}

.imageCaptionText {
	display: inline-block;
	font-size: var(--typography-body-s-size);
	font-style: italic;
	opacity: 1;
	text-align: right;
	transform: translateX(0);
	transition: transform var(--transition-speed-normal) ease-in-out;

	@media (--bp-medium) {
		font-size: var(--typography-body-size);
	}

	&[aria-hidden="true"] {
		transform: translateX(5px);
	}

	& a {
		color: inherit;
		text-decoration: underline;
	}
}

.imageCaptionButton {
	background-color: transparent;
	border: none;
	bottom: 0;
	color: var(--color-neutral-white);
	cursor: pointer;
	line-height: 1;
	padding: var(--spacing-2xs);
	position: absolute;
	right: 0;
	transition: opacity var(--transition-speed-normal);
	z-index: 11;

	&:hover,
	&:focus {
		opacity: 0.8;
	}

	&:focus-visible {
		outline-color: var(--color-theme-link-focus-outline-color);
		outline-offset: -1px;
	}
}

.imageCaptionIcon {
	align-items: center;
	background-color: var(--color-neutral-dark-20);
	border-radius: 50%;
	display: inline-flex;
	height: 28px;
	justify-content: center;
	width: 28px;
}
