.accordionButton {

	&[aria-expanded="true"] {

		& svg {
			transform: rotate(180deg);
		}
	}

	& svg {
		transition: transform var(--transition-speed-normal) ease-in-out;
	}
}

.accordionPanel {
	overflow: hidden;
	transition: max-height var(--transition-speed-normal) ease-in-out;

	&[aria-hidden="false"] {
		max-height: var(--max-height);
	}

	&[aria-hidden="true"] {
		max-height: 0;
	}
}
