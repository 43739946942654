.galleryImageWrapper {
	border-radius: var(--radius-media);
	margin: 0;
	overflow: hidden;
	position: relative;
}

.galleryImageWrapperFill {
	aspect-ratio: 4/3;

	& .galleryImage {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
}

.galleryImageWrapperCropped {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	& .galleryImage {
		flex: 1 0 0%;
		height: 100%;
		object-fit: cover;
		width: 100%;
	}
}

.galleryImage {
	display: block;
	height: auto;
	width: 100%;
}
