.articlesGrid {
	color: var(--color, currentColor);
	margin-block: var(--vertical-margin);
	margin-inline: var(--margin-full-width);
	max-width: var(--screen-width);
	padding: 0 var(--spacing-l);
}

.articlesGridTitle {
	--font-family: var(--typography-typeface-primary-family);
	--font-size: 2.25rem;
	--font-weight: 550;
	--line-height: 1;
	--margin-bottom: var(--spacing-xl);

	@media (--bp-medium) {
		--font-size: 3rem;
	}

	color: var(--color, currentColor);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	line-height: var(--line-height);
	margin-bottom: var(--margin-bottom);
	text-align: center;
}

.articleGridContainer {
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-l);
	margin: 0 auto;
	max-width: var(--max-width-grid);
}

.articleGridItem {
	--flex-items: 2;
	--transition: 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) calc(var(--index, 0) * 0.05s);
	--gap: var(--spacing-l);

	opacity: 0;
	transition: opacity var(--transition);
	width: calc(100% / var(--flex-items) - ((var(--flex-items) - 1) / var(--flex-items)) * var(--gap));

	@media (--allows-motion) {
		transform: translateY(-50px);
		transition: opacity var(--transition), transform var(--transition);
	}

	@media (--bp-medium) {
		--flex-items: 4;
	}
}

:global(.visible) .articleGridItem {
	opacity: 1;

	@media (--allows-motion) {
		transform: translateY(0);
	}
}

.articlesGridCta {
	margin-top: var(--spacing-xl);
	text-align: center;
}

.articlesGridHorizontal {

	& .articleGridItem {
		--flex-items: 1;

		@media (--bp-medium) {
			--flex-items: 2;
		}
	}
}

.articlesGridCollage {

	& .articleGridItem {

		&:nth-child(odd) {
			margin-top: 0;
		}

		&:nth-child(even) {
			margin-top: var(--spacing-xl);
		}
	}
}

.articlesGridContentCard {
	margin-left: 0;
	margin-right: 0;
	margin-top: inherit;
	max-width: none;
	padding: 0;

	& .articleGridContainer {
		padding: 0;
	}

	& .articleGridItem {
		--flex-items: 1;
		padding: var(--spacing-s);

		@media (--bp-medium) {
			padding: var(--spacing-xs);
		}
	}
}

:global(.wp-block-group) {

	& .articlesGrid {
		padding-inline: 0;
	}
}

.articleGridWall {
	--max-width-grid: 85rem;

	margin: var(--spacing-xl) 0;
	padding-inline: 0;

	@media (--bp-medium) {
		margin: var(--spacing-xl-desktop) var(--margin-full-width);
		padding-inline: var(--spacing-2xs);
	}
}

.articleGridWall .articleGridContainer {
	gap: var(--spacing-s) var(--spacing-2xs);

	@media (--bp-medium) {
		gap: var(--spacing-2xl) var(--spacing-s);
	}
}

.articleGridWall .articleGridItem {
	--gap: var(--spacing-2xs);

	@media (--bp-medium) {
		--gap: var(--spacing-s);
	}
}
