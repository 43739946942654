.customError {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	min-height: 600px;
	overflow: hidden;
	position: relative;

	&::after {
		background: var(--color-neutral-light-60);
		content: "";
		display: block;
		inset: 0;
		position: absolute;
		z-index: 0;
	}
}

.customErrorImageWrapper {
	height: 100%;
	left: 50%;
	margin: 0;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;

	&::after {
		background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 75%) 100%);
		bottom: 0;
		content: "";
		display: block;
		height: 100%;
		position: absolute;
		width: 100%;
	}
}

.customErrorImage {
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.customErrorContent {
	color: var(--color-neutral-white);
	padding: var(--spacing-3xl) var(--grid-l-gutter);
	position: relative;
	text-align: center;
	z-index: 1;

	& a {
		color: var(--color-neutral-white);
		text-decoration-color: var(--color-neutral-white);

		&:hover,
		&:focus {
			text-decoration-color: transparent;
		}
	}

	& p {
		--font-weight: 700;
	}

	& button {
		margin-top: var(--spacing-l-desktop);
	}
}

.customErrorTitle {
	--font-family: var(--typography-h-xl-family);
	--font-size: var(--typography-h-xl-size);
	--font-weight: var(--typography-h-xl-weight);
	--letter-spacing: var(--typography-h-xl-letter-spacing);
	--line-height: var(--typography-h-xl-line-height);

	@media (--bp-large) {
		--font-family: var(--typography-h-xl-desktop-family);
		--font-size: var(--typography-h-xl-desktop-size);
		--font-weight: 700;
		--letter-spacing: var(--typography-h-xl-desktop-letter-spacing);
		--line-height: var(--typography-h-xl-desktop-line-height);
	}

	margin-bottom: var(--spacing-s);
}

.customErrorCode {
	--font-size: var(--typography-h-2xl-size);

	@media (--bp-large) {
		--font-size: var(--typography-h-2xl-desktop-size);
	}

	display: block;
	font-size: var(--font-size);
	margin-bottom: var(--spacing-s);
}
