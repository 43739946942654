.poll {
	align-items: flex-start;
	border-block: var(--separator-thickness) solid var(--separator-color);
	display: flex;
	flex-direction: column;
	width: 100%;
}

.poll__heading {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-s);
	margin-block: var(--spacing-m);

	@media (--bp-large) {
		margin-block: var(--spacing-m-desktop);
	}
}

.poll__question {
	color: var(--color);
}

.poll__description {

	/* Mobile/Body - S */
	--font-family: var(--typography-body-s-family);
	--font-size: var(--typography-body-s-size);
	--font-weight: var(--typography-body-s-weight);
	--letter-spacing: var(--typography-body-s-letter-spacing);
	--line-height: var(--typography-body-s-line-height);

	@media (--bp-large) {

		/* Desktop/Body - S */
		--font-family: var(--typography-body-s-desktop-family);
		--font-size: var(--typography-body-s-desktop-size);
		--font-weight: var(--typography-body-s-desktop-weight);
		--letter-spacing: var(--typography-body-s-desktop-letter-spacing);
		--line-height: var(--typography-body-s-desktop-line-height);
	}

	color: var(--color-theme-caption);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.poll__optionsContainer {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-3xs);
	width: 100%;
}

.poll__option {
	--border-color: var(--color-neutral-dark-20);

	align-items: center;
	align-self: stretch;
	border: 1px solid var(--border-color);
	display: flex;
	gap: var(--spacing-2xs);
	overflow: hidden;
	padding: var(--spacing-2xs);
	text-overflow: ellipsis;

	& .poll__optionLabel {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: var(--spacing-xs);

		& label {
			margin: 0;

		}
	}

	& .poll__optionText {
		display: flex;
		justify-content: space-between;
	}

	&.poll__optionNonSelected {
		color: var(--color-neutral-dark-70);
	}

	&.poll__optionSelected {
		background: var(--color-neutral-dark-5);
		--border-color: var(--color-link);
	}

	&.poll__optionVoted {
		background: var(--color-neutral-dark-10);
	}

	&.poll__winner {
		background: var(--color-link);
	}

	&.poll__winner .poll__optionText {
		color: var(--color-neutral-white);
	}

	&.poll__winner .poll__optionPercentage {
		font-weight: var(--typography-typeface-primary-weight-bold);
	}
}

.poll__progressBar {
	--bar-background-color: var(--color-neutral-dark-10);
	--bar-color: var(--color-neutral-dark-40);

	&[value] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: var(--bar-background-color);
		border: none;
		height: 6px;
		width: 100%;

		&::-webkit-progress-bar {
			background: var(--bar-background-color);
		}

		&::-webkit-progress-value {
			background: var(--bar-color);
		}

		&::-moz-progress-bar {
			background: var(--bar-color);
		}
	}

	&.poll__progressBarVoted[value] {

		&::-webkit-progress-value {
			--bar-color: var(--color-link);
		}

		&::-moz-progress-bar {
			--bar-color: var(--color-link);
		}
	}

	&.poll__progressBarWinner[value] {

		&::-webkit-progress-value {
			--bar-color: var(--color-neutral-light-10);
		}

		&::-moz-progress-bar {
			--bar-color: var(--color-neutral-light-10);
		}
	}
}

.poll__footer {
	align-items: center;
	align-self: stretch;
	display: flex;
	flex-direction: row;
	gap: var(--spacing-2xs);
	justify-content: center;
	margin-block: var(--spacing-m);

	&:has(button) {
		justify-content: flex-start;
	}

	@media (--bp-large) {
		gap: var(--spacing-xs);
		margin-block: var(--spacing-s);
	}

	& .poll__voteButton {
		width: 160px;
	}

	& .poll__voteCount {

		/* Mobile/Caption */
		--color: var(--color-theme-caption);
		--font-family: var(--typography-caption-family);
		--font-size: var(--typography-caption-size);
		--letter-spacing: var(--typography-caption-letter-spacing);
		--line-height: var(--typography-caption-line-height);

		@media (--bp-large) {

			/* Desktop/Caption */
			--font-family: var(--typography-caption-desktop-family);
			--font-size: var(--typography-caption-desktop-size);
			--line-height: var(--typography-caption-desktop-line-height);
			--letter-spacing: var(--typography-caption-desktop-letter-spacing);
		}

		color: var(--color);
		display: flex;
		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--typography-typeface-primary-weight-bold);
		gap: var(--spacing-3xs);
		letter-spacing: var(--letter-spacing);
		line-height: var(--line-height);
		text-transform: uppercase;
	}
}

.poll__delimiterIcon {
	align-items: center;
	display: flex;

	& svg {
		height: 0.3em;
		width: 0.3em;
	}
}
