.menuContainer {
	--border-style: var(--navigation-border-width) var(--navigation-border-style) var(--navigation-color-border);
	--color: var(--navigation-color-text);
	--color-link: var(--navigation-color-link);

	background-color: var(--background-color);
	bottom: 0;
	box-shadow: 0 2px 15px 0 #0000000d;
	display: flex;
	flex-direction: column;
	height: calc(100vh - var(--newsletter-button-height)); /* Fallback when devices do not support svh units */
	height: 100dvh;
	position: fixed;
	right: 0;
	top: 0;
	transition: all var(--transition-speed-normal) ease-in-out;
	visibility: hidden;
	width: 100%;
	z-index: 100;

	@media (--bp-large) {
		box-shadow: none;
		height: auto;
		position: relative;
		visibility: visible;
	}
}

.menuContainerOpen {
	transform: translateX(0);
	visibility: visible;
}

.menuContainerClose {
	transform: translateX(100%);

	@media (--bp-large) {
		transform: translateX(0);
	}
}

.menuIsResizing {
	animation: none !important;
	transition: none !important;
}

/* Navigation */
.menuNavigation {
	border-bottom: var(--border-style);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-x: hidden;
	overflow-y: auto;
	overscroll-behavior-block: contain;
	row-gap: var(--spacing-xs);

	@media (--bp-large) {
		overflow: visible;
	}

}

.menuNavigationEyebrow {
	color: var(--color);
}

/* Menu Header */
.menuNavigationHeader {
	border-bottom: var(--border-style);
	color: var(--color);
	display: flex;
	justify-content: center;
	padding: var(--spacing-xs) var(--spacing-s);
	position: relative;

	@media (--bp-large) {
		display: none;
	}
}

.menuNavigationHeaderLink {
	color: var(--color-link);
	display: flex;
	transition: color var(--transition-speed-fast) ease-in-out;

	& img {
		height: 32px;
		width: 123px;

		@media (--bp-large) {
			height: 50px;
			width: 193px;
		}
	}
}

.menuNavigationCloseButton {
	appearance: none;
	background-color: transparent;
	border: 0;
	color: var(--color-link);
	cursor: pointer;
	display: inline-flex;
	padding: 0;
	position: absolute;
	right: var(--spacing-s);
	top: 50%;
	transform: translateY(-50%);
	transition: color var(--transition-speed-fast) ease-in-out;
}

/* Menu Footer */
.menuNavigationFooter {
	border-top: var(--border-style);
	margin-top: auto;
	padding: var(--spacing-m) var(--spacing-xs);

	@media (--bp-large) {
		display: none;
	}
}

.menuNavigationFooterNewsletterButton {
	justify-content: center;
	width: 100%;
}
